<template>
    <div class="row">        
       <LeftSection :list_check_box="list_check_box" :links="data.data_links" />
        
        <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">
            <template v-for="(item, key) in list_check_box"> 
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->
                    
                    <!-- Section Detail Start -->
                    <!--information_general start-->
                    <div class="content-onglets" v-if="key == 'detail_general_information' && item.show">
                        <table class="comparateur2 text-left border-top-gray">  
                            <tr v-if="data.health_canada['date']">
                                <td class="text-left bold"><strong> {{$t('Date filed')}} </strong></td>
                                <td class="text-left"> {{DDMMMYY(data.health_canada['date'])}} </td>
                            </tr>
                            <tr v-if="data.health_canada['submission']">
                                <td class="text-left bold"><strong> {{$t('Type of submission')}} </strong></td>
                                <td class="text-left"> {{DDMMMYY(data.health_canada['submission'])}} </td>
                            </tr>
                        </table> 
                    </div>
                    <!--information_general end--> 

                    <!--prescription_status start-->
                    <div class="content-onglets" v-else-if="key == 'detail_prescription_status' && item.show" >
                        <span v-html="data.health_canada['prescription_status'] ? data.health_canada['prescription_status'] : '-'"></span>
                    </div>
                    <!--prescription_status end-->
             
                    <!--purpose_submission start-->
                    <div class="content-onglets" v-else-if="key == 'detail_purpose_submission' && item.show" >
                        <span v-html="data.health_canada['purpose_submission'] ? data.health_canada['purpose_submission'] : '-' "></span>
                    </div>
                    <!--purpose_submission end-->
                    
                   <!--Decision issued start-->
                    <div class="content-onglets" v-else-if="key == 'detail_decision_issued_text' && item.show" >
                        <span v-html="data.health_canada['decision_issued_text'] ? data.health_canada['decision_issued_text'] : '-'"></span>
                    </div>
                    <!--Decision issued end--> 
                    
                    <!--identification start-->
                    <div class="content-onglets" v-else-if="key == 'detail_identification_json' && item.show" >
                     
                         <div class="content-onglets">
                            <table class="comparateur2 border-top-gray">
                               <tbody>                                   
                                   <tr v-for="(value, keys ) in data.health_canada['identification_json']" :key="'identification_json'+keys">
                                       <td>{{value.number}}</td>
                                   </tr>                                           
                               </tbody>
                           </table>  
                        </div>
                    </div>
                    <!--identification end-->

                    <!-- treatment_line start -->
                        <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->
                    
                    <!-- @todo  EssaisClinique start--> 
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>
                    <!-- EssaisClinique end   -->
            
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>

                    <!-- Section Detail End -->

                </div>
            </template>
            
            <!-- Link Section Start-->       
           <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->

        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue'
import TreatmentLine from '../treatment_line.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import LinkAgency from '../LinkAgency.vue'
import KeyDocument from '../key_document.vue'
import { DDMMMYY, key_documents } from '../../../utils'

export default {
    name: 'health_canada',
    components : {
        LeftSection,
        LinkAgency,
        TreatmentLine,
        EssaisClinique,
        KeyDocument
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_general_information : {'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_prescription_status : {'title' : 'Prescription status', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_purpose_submission : {'title' : 'Purpose of the submission', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_decision_issued_text : {'title' : 'Decision issued', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_identification_json : {'title' : 'Drug Identification Numbers issued', 'show' : true, 'enable' : false, 'orange_text':false},
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_clinical_trials : {'title' : 'Clinical Trials', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false},
            },
            identification : {},
            
                
        }
    },
    created(){
        // condition to show check box in left section [enable]
        if(this.data && this.data.health_canada){
            
            if (this.data.health_canada['date'] || this.data.health_canada['submission']){
                this.list_check_box['detail_general_information'].enable = true
            }
            if(this.data.health_canada['prescription_status'] ){ 
                this.list_check_box['detail_prescription_status'].enable = true
            }
            
            if(this.data.health_canada['purpose_submission'] ){ 
                this.list_check_box['detail_purpose_submission'].enable = true
            }
            
            if(this.data.health_canada['decision_issued_text'] ){ 
                this.list_check_box['detail_decision_issued_text'].enable = true
            }

            if(this.data.health_canada['identification_json'] ){                 
                this.list_check_box['detail_identification_json'].enable = true
            }

            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }
            
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }

            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }
            }
    },
    methods : {
        DDMMMYY,
        key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }        
			
    },
}
</script>

<style>

</style>